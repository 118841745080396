import React, { useEffect } from 'react';

const HubSpotForm = () => {
  useEffect(() => {
    // Create a script element to embed the HubSpot form
    const script = document.createElement('script');
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";

    // Once the script is loaded, create the HubSpot form
    script.onload = () => {
      window.hbspt.forms.create({
        region: "na1",  // Adjust based on your location
        portalId: "46304418",  // Replace with your actual HubSpot portal ID
        formId: "a9e695f4-b3b9-4996-b229-7a54b6e4de32",  // Replace with your HubSpot form ID
        target: "#hubspotForm"  // This is where the form will render in the div
      });
    };

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup function to remove the script if the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);  // Empty dependency array to only run the effect once when the component mounts

  return (
    <div>
      {/* This div is where the HubSpot form will be rendered */}
      <div id="hubspotForm"></div>
    </div>
  );
};

export default HubSpotForm;