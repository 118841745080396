import React, { FC, useEffect, useState } from "react";
import { SolutionsEnablementWrapper } from "./SolutionsEnablement.styled";
import { getEnablementSolutionsData } from "cms/helpers";
import { StructuredText } from "react-datocms";
import { useLocation } from "react-router-dom";
import { MarketingGreenButtonWrapper } from "components/MarketingHomepage/MarketingHomepage.styled";

interface SolutionsEnablementProps {}

interface Section {
  title: string;
  description: {
    value: any;
  };
  image: {
    url: string;
  };
}
interface SolutionsEnablementData {
  title: string;
  sections: Section[];
}

const SolutionsEnablement: FC<SolutionsEnablementProps> = () => {
  const [data, setData] = useState<SolutionsEnablementData>();
  const { hash } = useLocation();

  useEffect(() => {
    const getData = async () => {
      let response = await getEnablementSolutionsData();
      console.log(response);
      setData(response?.solutionsSalesEnablement);
    };
    getData();
  }, []);

  useEffect(() => {
    const hash = window.location.hash.substring(1); // Remove the '#' from the hash
    if (hash) {
      const sectionElement = document.getElementById(hash);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [data, hash]);

  return (
    <SolutionsEnablementWrapper>
      <h1>{data?.title}</h1>
      <div className="sections-wrapper">
        {data?.sections?.map((section: Section, index: number) => {
          //if index is odd, reverse the order
          if (index % 2 === 1) {
            return (
              <div
                className="section-wrapper-right"
                id={`section-${index}`}
                key={index}
              >
                <div className="flex-wrapper-right">
                  <img src={section.image?.url} />
                  <div className="text-right">
                    <h2>{section.title}</h2>
                    <StructuredText data={section.description?.value} />
                    <MarketingGreenButtonWrapper
                      onClick={() => {
                        window.location.assign(
                          "https://meetings.hubspot.com/jeff1418"
                        );
                      }}
                      width={250}
                    >
                      Book a demo
                    </MarketingGreenButtonWrapper>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div
                className="section-wrapper"
                id={`section-${index}`}
                key={index}
              >
                <div className="flex-wrapper">
                  <div className="text">
                    <h2>{section.title}</h2>
                    <StructuredText data={section.description?.value} />
                    <MarketingGreenButtonWrapper
                      onClick={() => {
                        window.location.assign(
                          "https://meetings.hubspot.com/jeff1418"
                        );
                      }}
                      width={250}
                    >
                      Book a demo
                    </MarketingGreenButtonWrapper>
                  </div>
                  <img src={section.image?.url} />
                </div>
              </div>
            );
          }
        })}
      </div>
    </SolutionsEnablementWrapper>
  );
};

export default SolutionsEnablement;
